function Splash() {
  return (
    <div className="splash">
      <div className="byline">
        <h2>Free Code Camp is the local chapter of FCC, the best way to learn coding for free!</h2>
      </div>
      <img alt='people having fun coding' src='/cottonbro-studio.jpg' />
    </div>
  )
}

export default Splash