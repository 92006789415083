function About() {
  return (
    <div id="about" className="about">
      <h2>About</h2>
      <p>Whether you are a beginner, or a pro, there is always something new to learn, or someone new to meet.</p>
      <h3>Here is why you should come</h3>
      <div className='center'>
        <ul className='come'>
          <li><b>☛</b > Get help in real time from our community Discord page.</li>
          <li><b>☛</b > Meet up with other techie Columbusites.</li>
          <li><b>☛</b > Learn to code at your own pace, in your browser or on your phone.</li>
          <li><b>☛</b > Work through our focused, interactive courses and tutorials.</li>
          <li><b>☛</b > Learn state-of-the-art full stack JavaScript tutorials.</li>
          <li><b>☛</b > Get real-world experience building projects that help local nonprofits.</li>
          <li><b>☛</b > Get a free certificate of completion.</li>
          <li><b>☛</b > Assemble a portfolio of real apps used by real people.</li>
        </ul>
      </div>
    </div >
  );
}

export default About;
